/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const ArrowheadUpIconSvg = forwardRef((props, svgRef) => (
	<svg
		data-name="Livello 1"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M17.51 14.5c0 .13-.05.26-.15.35-.2.2-.51.2-.71 0L12 10.2l-4.65 4.65c-.2.2-.51.2-.71 0s-.2-.51 0-.71l5-5c.2-.2.51-.2.71 0l5 5c.1.1.15.23.15.35z" />
	</svg>
))
ArrowheadUpIconSvg.displayName = 'ArrowheadUpIconSvg'

const ArrowheadUpIcon = forwardRef((props, ref) => <Icon component={ArrowheadUpIconSvg} ref={ref} {...props} />)
ArrowheadUpIcon.displayName = 'ArrowheadUpIcon'

ArrowheadUpIcon.defaultProps = {
	...Icon.defaultProps,
}
ArrowheadUpIcon.propTypes = {
	...Icon.propTypes,
}

export default ArrowheadUpIcon
export { ArrowheadUpIconSvg }
