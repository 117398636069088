/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const ArrowheadRightIconSvg = forwardRef((props, svgRef) => (
	<svg
		data-name="Livello 1"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M9.5 17.5a.47.47 0 01-.35-.15c-.2-.2-.2-.51 0-.71l4.65-4.65-4.65-4.65c-.2-.2-.2-.51 0-.71s.51-.2.71 0l5 5c.2.2.2.51 0 .71l-5 5c-.1.1-.23.15-.35.15z" />
	</svg>
))
ArrowheadRightIconSvg.displayName = 'ArrowheadRightIconSvg'

const ArrowheadRightIcon = forwardRef((props, ref) => <Icon component={ArrowheadRightIconSvg} ref={ref} {...props} />)
ArrowheadRightIcon.displayName = 'ArrowheadRightIcon'

ArrowheadRightIcon.defaultProps = {
	...Icon.defaultProps,
}
ArrowheadRightIcon.propTypes = {
	...Icon.propTypes,
}

export default ArrowheadRightIcon
export { ArrowheadRightIconSvg }
